import React, { useRef, useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import Stack from 'react-bootstrap/Stack';
import Cookies from 'js-cookie';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import recognizeLabel from '../utilities/LabelRecognition'

const LabelPage = () => {

	const [data, setData] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const cookie = Cookies.get('cdexuser');
				const response = await fetch('https://www.cropdex.org/image-data/image-annotation-uniques', { method: 'GET', headers: { 'Authorization': `Bearer ${cookie}`, 'Content-Type': 'application/json'}});
				const jsonData = await response.json();
				setData(jsonData.label_data);
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};
		fetchData();
	}, []);

	return (
		<Container fluid gap={3}>
			<Row key={2} className="m-2 p-2">
				<Col><b>Actual Label</b></Col>
				<Col className="d-flex justify-content-center"><b>Crop Name</b></Col>
				<Col className="d-flex justify-content-center"><b>Insect Pest or Disease</b></Col>
				<Col className="d-flex justify-content-center"><b>Recognized Annotations</b></Col>
				<Col className="d-flex justify-content-center"><b>Valid Images</b></Col>
				<Col className="d-flex justify-content-center"><b>Label Recognition</b></Col>
				{/*<Col className="d-flex justify-content-center"><b>Buttons</b></Col>*/}
			</Row>
			{
				data.map((label, i) => {
					return (
						<Row key={i+3} className="border border-dark m-2 p-2">
							<Col>{label.pest_disease_label}</Col>
							<Col className="d-flex justify-content-center">{label.crop_name}</Col>
							<Col className="d-flex justify-content-center">{label.label_type}</Col>
							<Col className="d-flex justify-content-center">{label.count}</Col>
							<Col className="d-flex justify-content-center">{label.image_count}</Col>
							<Col className="d-flex justify-content-center"><i>{recognizeLabel(label.pest_disease_label).recognizedLabel ? "Recognized": "Not Recognized"}</i></Col>
							{/*<Col className="d-flex justify-content-center"><Button variant="primary">Save</Button></Col>*/}
						</Row>
					)
				})
			}
		</Container>
	);
}

export default LabelPage;