import React, { useRef, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HeatMapContainer from './components/map/HeatMapContainer';
import CropListPage from './components/image-library/CropListPage';
import FilteredCropImageGalleryPage from './components/image-library/FilteredCropImageGalleryPage';
import AssociationsPage from './components/associations/AssociationsPage';
import AssociationInfoPage from './components/associations/AssociationInfoPage'
import CropdexNavbar from './components/navigation/CropdexNavbar';
import LoginComponent from './components/authorization/Login';
import EditCropImagePage from './components/annotation/EditCropImagePage';
import Statistics from './components/statistics/Statistics';
import LabelPage from './components/label/LabelPage';

function App() {

	return (
		<div style={{ backgroundColor: '#f8f9fa' }}>
			<Router>
				<CropdexNavbar/>
				<Routes>
					<Route path="/" 
						element={<HeatMapContainer/>}
					/>
					<Route path="/login" 
						element={<LoginComponent/>} 
					/>
					<Route path="/associations"
						element={<AssociationsPage/>}
					/>
					<Route path="/crops"
						element={<CropListPage/>}
					/>
					<Route path="/crops/:crop_id"
						element={<FilteredCropImageGalleryPage/>}
					/>
					<Route path="/crop-images/:crop_id/:bool_require_validation/:bool_require_evaluation/:bool_valid/:bool_invalid/:bool_pest/:bool_disease/:bool_unclassified/:bool_include_no_annotations/:imageid"
						element={<EditCropImagePage/>}
					/>
					<Route path="/association-info/:associd"
						element={<AssociationInfoPage />}
					/>
					<Route path="/statistics"
						element={<Statistics/>}
					/>
					<Route path="/labels"
						element={<LabelPage/>}
					/>
					{/*<Route path="/contact" component={Contact} />*/}
				</Routes>
			</Router>
		</div>
	);
}

export default App;