import React, { useRef, useEffect, useState } from "react";
import { useParams, useNavigate} from 'react-router-dom';
import Cookies from 'js-cookie';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import Spinner from 'react-bootstrap/Spinner';
import Badge from 'react-bootstrap/Badge';
import ImagePaginationComponent from '../utilities/ImagePaginationComponent';
import {retrieveCropName} from "../utilities/CropProvider";
import { arrayChunk } from '../utilities/ArrayChunkers'
import '../../App.css';

const FilteredCropImageGalleryPage = () => {

	let navigate = useNavigate();
	function changeLocation(placeToGo){
		navigate(placeToGo);
		navigate(0)
	}

	const { crop_id } = useParams();
	const [data, setData] = useState();

	const [currentPage, setCurrentPage] = useState(1);
	const [numberOfPages, setNumberOfPages] = useState(1);
	const [paginationArray, setPaginationArray] = useState([]);

	const [checkRequireValidation, setCheckRequireValidation] = useState(true);
	const [checkRequireEvaluation, setCheckRequireEvaluation] = useState(false);
	const [checkValid, setCheckValid] = useState(false);
	const [checkInvalid, setCheckInvalid] = useState(false);

	const [checkPest, setCheckPest] = useState(true);
	const [checkDisease, setCheckDisease] = useState(false);
	const [checkUnclassified, setCheckUnclassified] = useState(false);
	const [checkIncludeNoAnnotations, setCheckIncludeNoAnnotations] = useState(false);

	const [numberOfResults, setNumberOfResults] = useState(0);
	
	useEffect(() => {
		const fetchData = async () => {
			try {
				const cookie = Cookies.get('cdexuser');
				const image_query = `https://www.cropdex.org/crops/crop-images-full-filter?crop_id=${crop_id}&validity_requires_validation=${checkRequireValidation}&validity_requires_evaluation=${checkRequireEvaluation}&validity_valid=${checkValid}&validity_invalid=${checkInvalid}&label_pest=${checkPest}&label_disease=${checkDisease}&label_unclassified=${checkUnclassified}&special_include_no_annotations=${checkIncludeNoAnnotations}`;
				const response = await fetch(image_query, { method: 'GET', headers: { 'Authorization': `Bearer ${cookie}`, 'Content-Type': 'application/json'}});
				const jsonData = await response.json();
				setData(jsonData);
				setNumberOfResults(jsonData.length);
				let pageCount = Math.max(1, Math.ceil(jsonData.length/15));
				setNumberOfPages(pageCount);
				setCurrentPage(1);
			} catch (error) { 
				console.error('Error fetching data:', error);
			}
		};
		fetchData();
	}, [checkRequireValidation, checkRequireEvaluation, checkValid, checkInvalid, checkPest, checkDisease, checkUnclassified, checkIncludeNoAnnotations]);

	return (
		<div className="image-list-div" style={{ marginTop: '20px' }}>
			{ data != null && data != undefined ?
				(
					<>
						<Container fluid>

							<Row className="align-items-center">
								<Col xs="auto">
									<h3>{retrieveCropName(crop_id)} Image Results <Badge bg="success">{numberOfResults}</Badge></h3>
								</Col>

								<Col>
									<Row className="justify-content-end">
										<Col xs="auto">
											<Form>
											{/* Checkboxes Row 1 */}
												<Row>
													<Col xs="auto">
														<Form.Check
															inline
															type="checkbox"
															id="reqvalidationbox"
															label="Requires Validation"
															checked={checkRequireValidation}
															onChange={() => {setCheckRequireValidation(!checkRequireValidation)}}
														/>
													</Col>
													<Col xs="auto">
														<Form.Check
															inline
															type="checkbox"
															id="reqevaluationbox"
															label="Requires Evaluation"
															checked={checkRequireEvaluation}
															onChange={() => {setCheckRequireEvaluation(!checkRequireEvaluation)}}
														/>
													</Col>
													<Col xs="auto">
														<Form.Check
															inline
															type="checkbox"
															id="validbox"
															label="Valid"
															checked={checkValid}
															onChange={() => {setCheckValid(!checkValid)}}
														/>
													</Col>
													<Col xs="auto">
														<Form.Check
															inline
															type="checkbox"
															id="invalidbox"
															label="Invalid"
															checked={checkInvalid}
															onChange={() => {setCheckInvalid(!checkInvalid)}}
														/>
													</Col>
												</Row>
												{/* Checkboxes Row 2 */}
												<Row>
													<Col xs="auto">
														<Form.Check
															inline
															type="checkbox"
															id="pestbox"
															label="Pests"
															checked={checkPest}
															onChange={() => {setCheckPest(!checkPest)}}
														/>
													</Col>
													<Col xs="auto">
														<Form.Check
															inline
															type="checkbox"
															id="diseasebox"
															label="Diseases"
															checked={checkDisease}
															onChange={() => {setCheckDisease(!checkDisease)}}
														/>
													</Col>
													<Col xs="auto">
														<Form.Check
															inline
															type="checkbox"
															id="unclassifiedbox"
															label="Unclassified"
															checked={checkUnclassified}
															onChange={() => {setCheckUnclassified(!checkUnclassified)}}
														/>
													</Col>
													<Col xs="auto">
														<Form.Check
															inline
															type="checkbox"
															id="includenoannotationbox"
															label="Include No Annotations"
															checked={checkIncludeNoAnnotations}
															onChange={() => {setCheckIncludeNoAnnotations(!checkIncludeNoAnnotations)}}
														/>
													</Col>
												</Row>
											</Form>
										</Col>
									</Row>
								</Col>
							</Row>
						{
							arrayChunk(data.slice((currentPage-1)*15, currentPage*15), 5).map((row, i) => (
								<Row key={i}>
									{row.map((col, j) => (
										<Col key={j+i*5} xs={5} md={3} lg={2} className="mb-3" lg={true}>
											<div className="image-gallery-container">
												<div className="square">
													<Image 
														className="img-fluid square-img"
														src={"https://www.cropdex.org/image-data/retrieve-image?id=" + col.id}
														onClick={() => { 
															changeLocation(`/crop-images/${crop_id}/${checkRequireValidation}/${checkRequireEvaluation}/${checkValid}/${checkInvalid}/${checkPest}/${checkDisease}/${checkUnclassified}/${checkIncludeNoAnnotations}/${col.id}`)
														}}/>
												</div>
												<div className="bottom-left-text">By: {col.cropdex_user.display_name}<br/>Date: {col.date_taken}</div>
											</div>
										</Col>
									))}
									{row.length < 5 ?
										Array.from({ length: 5-row.length }).map((_, index) => (
											<Col key={i*5+row.length+index}></Col>
										))
									:null
									}
								</Row>
							))
						}
						<Row>
						<h5 className="text-center">Showing {(currentPage-1)*15 + 1}-{(Math.min(currentPage*15,data.length))} of {numberOfResults} Images</h5>
						</Row>
						</Container>
						<ImagePaginationComponent 
							currentPage={currentPage} 
							numberOfPages={numberOfPages} 
							setCurrentPage={setCurrentPage}/>
					</>
				)
				: <Spinner animation="border" />
			}
		</div>
	);
}

export default FilteredCropImageGalleryPage;